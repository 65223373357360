<template>
  <div>
    <!-- 위험등록부 목록 -->
    <c-table
      ref="table"
      title="LBL0001203"
      tableId="riskRegister01"
      :columns="gridRisk.columns"
      :data="riskReduce.riskbooks" 
      :merge="gridRisk.merge"
      :gridHeight="setheight"
      :editable="editable&&!disabled"
      selection="multiple"
      rowKey="ramSignificantRiskRegisterId"
      checkDisableColumn="riskRegisterFlag"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable&&!disabled" label="LBLREMOVE" icon="remove" @btnClicked="removeRisk" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name==='riskHazardPicture'">
          <q-btn 
            unelevated round dense
            size="7px"
            color="primary" 
            icon="wallpaper"
            @click.stop="openHazardPicture(props)" />
        </template>
        <template v-else-if="col.name==='picture'">
          <q-btn 
            unelevated round dense
            size="7px"
            color="primary" 
            icon="wallpaper"
            @click.stop="openImprPicture(props)" />
        </template>
        <template v-else-if="col.name==='ramRiskLevelName'">
          <q-chip text-color="white" :style="`background-color:${props.row.riskColor} !important;color:white;`" outline square>
            {{props.row[col.name]}}
          </q-chip>
        </template>
        <template v-else-if="col.name==='ramAfterRiskLevelName'">
          <q-chip text-color="white" :style="`background-color:${props.row.riskColorAfter} !important;color:white;`" outline square>
            {{props.row[col.name]}}
          </q-chip>
        </template>
        <template v-else-if="col.name==='customCol'">
          <component
            :is="imprComponent"
            :col="col"
            :props="props"
            :inputEditable="false"
            :requestContentsCols="requestContentsCols"
            tableKey="ramThirdAssessScenarioId"
            ibmTaskTypeCd="ITT0000013"
            ibmTaskUnderTypeCd="ITTU000015"
            @imprChange="imprChange"
          />
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'thirdRiskRegister',
  props: {
    param: {
      type: Object,
      default: function() {
        return {
          ramRiskAssessmentPlanId: '',
          ramStepCd: '',
        }
      },
    },
    riskReduce: {
      type: Object,
      default: function() {
        return {
          imprs: [],
          riskbooks: [],
        }
      },
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      gridRisk: {
        merge: [
          { index: 0, colName: 'processName' },
        ],
        columns: [
          {
            fix: true,
            name: 'processName',
            field: 'processName',
            label: 'LBLPROCESS',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            fix: true,
            name: 'hazardDisasterTypeName',
            field: 'hazardDisasterTypeName',
            label: '재해유형',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            fix: true,
            name: 'riskHazardQuestionName',
            field: 'riskHazardQuestionName',
            // 유해위험요인<br/>(위험한 상황과 사건)
            label: 'LBL0001150',
            align: 'left',
            style: 'width:350px',
            sortable: false,
          },
          {
            fix: true,
            name: 'riskHazardPicture',
            field: 'riskHazardPicture',
            // 사진
            label: 'LBL0001175',
            align: 'center',
            style: 'width:40px',
            sortable: false,
            type: 'custom',
          },
          // {
          //   name: 'relationLaw',
          //   field: 'relationLaw',
          //   // 관련법규
          //   label: 'LBL0001021',
          //   align: 'left',
          //   style: 'width:100px',
          //   sortable: false,
          // },
          {
            name: 'currentSafetyMeasures',
            field: 'currentSafetyMeasures',
            // 현재안전조치
            label: 'LBL0001025',
            align: 'left',
            style: 'width:180px',
            sortable: false,
          },
          {
            name: 'ramRiskLevelName',
            field: 'ramRiskLevelName',
            // 판단결과
            label: '개선 전<br/>판단결과',
            align: 'center',
            style: 'width:100px',
            sortable: false,
            type: 'custom'
          },
          {
            name: 'picture',
            field: 'picture',
            // 개선<br/>전/후<br/>사진
            label: 'LBL0001176',
            align: 'center',
            type: 'custom',
            style: 'width:70px',
            sortable: false,
          },
          {
            name: 'ramAfterRiskLevelName',
            field: 'ramAfterRiskLevelName',
            // 판단결과
            label: '개선 후<br/>판단결과',
            align: 'center',
            style: 'width:100px',
            sortable: false,
            type: 'custom'
          },
          {
            name: 'improvementMeasures',
            field: 'improvementMeasures',
            // 개선대책
            label: '개선대책',
            align: 'left',
            style: 'width:180px',
            sortable: false,
          },
          {
            name: 'assessDate',
            field: 'assessDate',
            // 평가일
            label: 'LBL0001013',
            align: 'center',
            style: 'width:130px',
            sortable: false,
          },
          {
            name: 'assessUserName',
            field: 'assessUserName',
            // 평가자
            label: 'LBL0001014',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'customCol',
            field: 'customCol',
            label: 'LBLIMPROVE',
            align: 'center',
            style: 'width:150px',
            type: 'custom',
            sortable: false
          },
        ],
        data: [],
        height: '500px'
      },
      imprComponent: () => import(`${'@/pages/common/ibm/tableImpr.vue'}`),
      editable: true,
      deleteUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
    setheight() {
      let pxRemove = this.height.replace('px', '');
      return this.height && this.height.indexOf('px') > -1 && !isNaN(pxRemove) ? (Number(pxRemove) - 50) + 'px' : '500px'
    },
    disabled() {
      return this.param.ramStepCd === 'RRS0000015'
    },
    requestContentsCols() {
      return ['riskHazardQuestionName'];
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.deleteUrl = this.$format(transactionConfig.ram.riskRegister.delete.url, 'ITT0000013')
      // code setting
      // list setting
    },
    research() {
      this.$emit('research');
    },
    imprChange() {
      this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
      this.research();
    },
    /* eslint-disable no-unused-vars */
    removeRisk() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            let deleteData = [];
            this.$_.forEach(selectData, item => {
              deleteData.push({
                ramSignificantRiskRegisterId: item.ramSignificantRiskRegisterId,
                ramRiskAssessmentPlanId: item.ramRiskAssessmentPlanId,
                processCd: item.processCd,
                scenarioId: item.ramThirdAssessScenarioId,
              })
            })
            this.$http.url = this.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(deleteData),
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.riskReduce.riskbooks.splice(this.$_.findIndex(this.riskReduce.riskbooks, { ramSignificantRiskRegisterId: item.ramSignificantRiskRegisterId }), 1)
              })
              this.$refs['table'].$refs['compo-table'].clearSelection();
              this.research();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    openHazardPicture(props) {
      this.popupOptions.title = 'LBL0001194'; // 유해위험요인(위험한 상황과 사건) 사진
      this.popupOptions.param = {
        ramThirdAssessScenarioId: props.row.ramThirdAssessScenarioId,
        disabled: this.disabled 
      };
      this.popupOptions.target = () => import(`${'@/pages/ram/third/action/thirdRiskHazardPicture.vue'}`);
      this.popupOptions.width = '30%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeHazardPicturePopup;
    },
    closeHazardPicturePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    openImprPicture(props) {
      this.popupOptions.title = 'LBL0001181'; // 개선 사진
      this.popupOptions.param = {
        ramThirdAssessScenarioId: props.row.ramThirdAssessScenarioId,
        sopImprovementIds: props.row.sopImprovementIds,
        disabled: true
      };
      this.popupOptions.target = () => import(`${'@/pages/ram/third/action/thirdScenarioImprPicture.vue'}`);
      this.popupOptions.width = '30%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeScenarioImprPicturePopup;
    },
    closeScenarioImprPicturePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>