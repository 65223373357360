var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-table",
        {
          ref: "table",
          attrs: {
            title: "LBL0001203",
            tableId: "riskRegister01",
            columns: _vm.gridRisk.columns,
            data: _vm.riskReduce.riskbooks,
            merge: _vm.gridRisk.merge,
            gridHeight: _vm.setheight,
            editable: _vm.editable && !_vm.disabled,
            selection: "multiple",
            rowKey: "ramSignificantRiskRegisterId",
            checkDisableColumn: "riskRegisterFlag",
          },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props, col }) {
                return [
                  col.name === "riskHazardPicture"
                    ? [
                        _c("q-btn", {
                          attrs: {
                            unelevated: "",
                            round: "",
                            dense: "",
                            size: "7px",
                            color: "primary",
                            icon: "wallpaper",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.openHazardPicture(props)
                            },
                          },
                        }),
                      ]
                    : col.name === "picture"
                    ? [
                        _c("q-btn", {
                          attrs: {
                            unelevated: "",
                            round: "",
                            dense: "",
                            size: "7px",
                            color: "primary",
                            icon: "wallpaper",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.openImprPicture(props)
                            },
                          },
                        }),
                      ]
                    : col.name === "ramRiskLevelName"
                    ? [
                        _c(
                          "q-chip",
                          {
                            style: `background-color:${props.row.riskColor} !important;color:white;`,
                            attrs: {
                              "text-color": "white",
                              outline: "",
                              square: "",
                            },
                          },
                          [_vm._v(" " + _vm._s(props.row[col.name]) + " ")]
                        ),
                      ]
                    : col.name === "ramAfterRiskLevelName"
                    ? [
                        _c(
                          "q-chip",
                          {
                            style: `background-color:${props.row.riskColorAfter} !important;color:white;`,
                            attrs: {
                              "text-color": "white",
                              outline: "",
                              square: "",
                            },
                          },
                          [_vm._v(" " + _vm._s(props.row[col.name]) + " ")]
                        ),
                      ]
                    : col.name === "customCol"
                    ? [
                        _c(_vm.imprComponent, {
                          tag: "component",
                          attrs: {
                            col: col,
                            props: props,
                            inputEditable: false,
                            requestContentsCols: _vm.requestContentsCols,
                            tableKey: "ramThirdAssessScenarioId",
                            ibmTaskTypeCd: "ITT0000013",
                            ibmTaskUnderTypeCd: "ITTU000015",
                          },
                          on: { imprChange: _vm.imprChange },
                        }),
                      ]
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable && !_vm.disabled
                    ? _c("c-btn", {
                        attrs: { label: "LBLREMOVE", icon: "remove" },
                        on: { btnClicked: _vm.removeRisk },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }